import React from "react";
import Slider from "react-slick";
import "../ui/css/SessionStats.css";
import shape from "../assests/img/red vector.png";
import { ExtractFirstLetter, GetOrdinalSuffix } from "../utils/functions";

const ChampionshipRecordRow = ({ champResults }) => {
  const validSessions = champResults?.sessions?.filter(
    (session) =>
      session.total_players > 0 &&
      session.players?.some((player) => player.score > 0)
  );

  const settings = {
    dots: false,
    infinite: validSessions && validSessions?.length > 1,
    speed: 1400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  if (!validSessions || validSessions.length === 0) {
    return (
      <div className="mt-4 livvic text-white result-data-text uppercase text-center">
        Sessions Data not available for championship sessions...
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-4 livvic mt-4">
      <Slider arrows={false} {...settings}>
        {validSessions?.map(
          (session, ind) =>
            session && (
              <div key={ind} className="session-container ">
                <div className="flex flex-col items-center gap-4 min-w-[90%] ">
                  {session.players
                    ?.sort((a, b) => b.score - a.score)
                    ?.map((val, ind) => (
                      <div
                        key={ind}
                        className=" w-full rounded-[0.75rem] border-[0.125rem] uppercase shadow-custom-inset border-solid border-[#fff]  flex items-center justify-between pt-[10px] pr-[20px] pb-[10px] pl-[20px] md:pt-[10px] md:pr-[20px] md:pb-[10px] md:pl-[20px]"
                      >
                        <div className=" w-[15%] font-semibold text-white result-data-text !capitalize">
                          <h1>{GetOrdinalSuffix(val.position + 1)}</h1>
                        </div>

                        <div className="players-sec w-[30%]">
                          <div className=" w-[4rem] h-[4rem] flex items-center justify-center bg-white rounded-full ">
                            <span className="font-[900] text-[#c42121] uppercase text-[2.3rem]">
                              {ExtractFirstLetter(
                                val.driver_name ? val.driver_name : "-"
                              )}
                            </span>
                          </div>
                          <h1 className="result-data-text capitalize">
                            {val.driver_name ? val.driver_name : "-"}
                          </h1>
                        </div>

                        <div className="w-[15%] text-center result-data-text ">
                          <h1>{val.score}</h1>
                        </div>

                        <div className="w-[30%] result-data-text">
                          <h1>
                            {val.team_id && val.team_id.team_name
                              ? val.team_id.team_name
                              : "-"}
                          </h1>
                        </div>
                      </div>
                    ))}

                  <div className="relative max-h-[220px] max-w-[20%] overflow-hidden">
                    <img
                      src={shape}
                      alt="shape here"
                      className=" z-[0] h-full w-full object-contain  "
                    />

                    <h2 className="child z-[1] w-full text-center text-white livvic  text-[2rem] ">
                      {session.session_name ? session.session_name : "-"}
                    </h2>
                  </div>
                </div>
              </div>
            )
        )}
      </Slider>
    </div>
  );
};

export default ChampionshipRecordRow;
