import React, { useEffect, useState } from "react";
import "../common/ui/css/TeamStanding.css";
// import { getTeamStandingAPI } from "../common/utils/service/player.service";

const dummyData = [
  { rank: "A", name: "Team 1" },
  { rank: "B", name: "Team 2" },
  { rank: "C", name: "Team 3" },
  { rank: "D", name: "Team 4" },
];

const TeamStanding = () => {
  const [teamStanding, setTeamStanding] = useState();

  const getData = async () => {
    try {
      // const response = await getTeamStandingAPI();
      // setTeamStanding(response);
      setTeamStanding(dummyData);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      id="team-standing"
      className="main-container top-container bg-teamstanding-bgimage"
    >
      <div className="ls-card-wrapper">
        <div className="ls-card">
          <div className="lss-h-wrapper">
            <h1 className="live-standing-h kenyanCoffee text-center">
              Team Standing!
            </h1>
          </div>

          <div className="lss-btn-wrap">
            {teamStanding &&
              teamStanding.slice(0, 3).map((val, ind) => {
                return (
                  <a
                    key={ind}
                    href="a"
                    className={
                      ind % 2 === 0
                        ? "scan-button lss-btn"
                        : "reverted-scan-button lss-btn"
                    }
                  >
                    <div className="lss-sec">
                      <div className="avatar">
                        <span className="avatar-text">{val.rank}</span>
                      </div>
                      <h1 className="lss-btn-text livvic">{val.name}</h1>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default TeamStanding;
