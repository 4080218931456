import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DataLoadingSkeleton() {
  return (
    <div className=" mt-4 border-[0.125rem] rounded-[1rem] overflow-hidden">
      <SkeletonTheme>
        <Skeleton
          className=" flex items-center justify-center"
          width={"100%"}
          height={"70px"}
          baseColor="#c3212170"
          highlightColor="#056ae660"
          duration={1.4}
        />
      </SkeletonTheme>
    </div>
  );
}

export default DataLoadingSkeleton;
