import React from "react";
import "../ui/css/RankLiveStanding.css";

function MainHeading({ title, class_name }) {
  return (
    <>
      <h1
        className={`${
          class_name
            ? class_name
            : "lsr-live-standing-h kenyanCoffee text-center uppercase text-white text-[5rem]"
        }`}
      >
        {title ? title : "- - - -"}
      </h1>
    </>
  );
}

export default MainHeading;
