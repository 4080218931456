import React from "react";
import { PAGES_ROUTES } from "../../routes/index";
import { Link } from "react-router-dom";
import routes from "../../routes";

const Navbar = () => {
  return (
    <div className="flex-col h-[95vh] bg-gray-100   flex items-center justify-center w-full">
      <div className="w-[50%] py-10  bg-gray-700 rounded-lg flex flex-wrap justify-center gap-4">
        {routes.map(({ path }) => (
          <Link
            key={path}
            to={path}
            className="text-white px-3 py-4 w-[30%] rounded-sm text-center font-medium capitalize border"
          >
            {path === PAGES_ROUTES.welcomeracepark
              ? "welcometoracepark"
              : path.slice(1)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
