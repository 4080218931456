import CreateProfile from "../pages/CreateProfile.page";
import GetReady from "../pages/GetReady.page";
import GoodLuck from "../pages/GoodLuck.page";
import Home from "../pages/Home.page";
import LiveStanding from "../pages/LiveStanding.page";
import LiveStandingRank from "../pages/LiveStandingRank.page";
import PlayerResult from "../pages/PlayerResult.page";
import ScanCode from "../pages/ScanCode.page";
import SessionComplete from "../pages/SessionComplete.page";
import TeamStanding from "../pages/TeamStanding.page";
import WelcomePlayer from "../pages/WelcomePlayer.page";
import BeforeSessionStart from "../pages/BeforeSessionStart.page";
import WelcomeToRacePark from "../pages/WelcomeToRacePark.page";
import SessionStats from "../pages/SessionStats.page";
import ChampionshipStats from "../pages/ChampionshipStats.page";

export const PAGES_ROUTES = {
  welcomeracepark: "/",
  beforesessionstart: "/beforesessionstart",
  liveStandingRank: "/livestandingrank",
  sessionstats: "/sessionstats",
  championshipstats: "/championshipstats",

  home: "/home",
  createProfile: "/createprofile",
  getReady: "/getready",
  goodLuck: "/goodluck",
  liveStanding: "/livestanding",
  playerResult: "/playerresult",
  scanCode: "/scancode",
  sessionComplete: "/sessioncomplete",
  teamStanding: "/teamstanding",
  welcomePlayer: "/welcomeplayer",
};

const routes = [
  { path: PAGES_ROUTES.welcomeracepark, Component: WelcomeToRacePark },
  { path: PAGES_ROUTES.beforesessionstart, Component: BeforeSessionStart },
  { path: PAGES_ROUTES.liveStandingRank, Component: LiveStandingRank },
  { path: PAGES_ROUTES.sessionstats, Component: SessionStats },
  { path: PAGES_ROUTES.championshipstats, Component: ChampionshipStats },

  { path: PAGES_ROUTES.home, Component: Home },
  {
    path: `${PAGES_ROUTES.createProfile}/:sessionID`,
    Component: CreateProfile,
  },
  { path: `${PAGES_ROUTES.getReady}/:playerID`, Component: GetReady },
  { path: `${PAGES_ROUTES.goodLuck}/:playerID`, Component: GoodLuck },
  { path: PAGES_ROUTES.liveStanding, Component: LiveStanding },
  { path: `${PAGES_ROUTES.playerResult}/:playerID`, Component: PlayerResult },
  { path: PAGES_ROUTES.scanCode, Component: ScanCode },
  { path: PAGES_ROUTES.sessionComplete, Component: SessionComplete },
  { path: PAGES_ROUTES.teamStanding, Component: TeamStanding },
  { path: `${PAGES_ROUTES.welcomePlayer}/:playerID`, Component: WelcomePlayer },
];

export default routes;
