import { FaCheckCircle } from "react-icons/fa";
import React from "react";
import DataLoadingSkeleton from "./loader";
import { GetOrdinalSuffix } from "../utils/functions";

function SessionRecordRow({ sessionData }) {
  if (!sessionData || sessionData.length === 0) {
    return <DataLoadingSkeleton />;
  }

  return (
    <>
      {sessionData &&
        sessionData
          ?.sort((a, b) => b.total_score - a.total_score)
          .map((val, ind) => {
            return (
              <div
                className="mt-4 w-full shadow-custom-inset border-[0.125rem] rounded-[1rem] border-solid border-[#ffffff] livvic flex items-center justify-between pt-[20px] pr-[20px] pb-[10px] pl-[20px] md:pt-[20px] md:pr-[20px] md:pb-[20px] md:pl-[20px]"
                key={ind}
              >
                <div className="w-[5%]  ">
                  <h1 className="result-data-text">
                    {GetOrdinalSuffix(ind + 1)}
                  </h1>
                </div>

                <div className="players-sec w-[17%] ">
                  <h1 className="result-data-text capitalize line-clamp-1 ">
                    {val.player_name ? val.player_name : "-"}
                  </h1>
                </div>

                <div className="w-[17%]  ">
                  <h1 className="result-data-text line-clamp-1">
                    {val.team_id && val.team_id.team_name
                      ? val.team_id.team_name
                      : "-"}
                  </h1>
                </div>

                <div className="w-[5%]  text-center">
                  <h1 className="result-data-text">{val.lap_count}</h1>
                </div>

                <div className="w-[9%]  text-center">
                  <h1 className="result-data-text">{val.overtake}</h1>
                </div>

                <div className="w-[6%]  text-center">
                  <h1 className="result-data-text">{val.collision_with_car}</h1>
                </div>

                <div className="w-[6%]  text-center">
                  <h1 className="result-data-text">
                    {val.collision_with_environment}
                  </h1>
                </div>

                <div className="w-[14%] flex justify-center ">
                  {val.is_car_overtaken ? (
                    <h2 className="result-data-text ">-</h2>
                  ) : (
                    <FaCheckCircle className="result-data-text !text-green-400" />
                  )}
                </div>

                <div className="w-[12%] flex justify-center ">
                  {val.min_collisions ? (
                    <FaCheckCircle className="result-data-text !text-green-400" />
                  ) : (
                    <h2 className="result-data-text">-</h2>
                  )}
                </div>

                <div className="w-[7%]  text-center">
                  <h1 className="result-data-text">{val.total_score}</h1>
                </div>
              </div>
            );
          })}
    </>
  );
}

export default SessionRecordRow;
