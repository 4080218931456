import React, { useEffect, useState } from "react";
import "../common/ui/css/LiveStanding.css";
// import { getLiveStandingAPI } from "../common/utils/service/player.service";

const dummyData = [
  { rank: "A", name: "Player 1" },
  { rank: "B", name: "Player 2" },
  { rank: "C", name: "Player 3" },
  { rank: "D", name: "Player 4" },
  { rank: "E", name: "Player 5" },
  { rank: "F", name: "Player 6" },
];

const LiveStanding = () => {
  const [liveStanding, setLiveStanding] = useState();

  const getData = async () => {
    try {
      // const response = await getLiveStandingAPI();
      // setLiveStanding(response);
      setLiveStanding(dummyData);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      id="live-standing"
      className="main-container  bg-livestanding-bgimage "
    >
      <div className="ls-card-wrapper">
        <div className="ls-card">
          <div className="lss-h-wrapper">
            <h1 className="live-standing-h kenyanCoffee text-center">
              Live Standing!
            </h1>
          </div>

          <div className="lss-btn-wrap">
            {liveStanding &&
              liveStanding.slice(0, 3).map((val, ind) => {
                return (
                  <a
                    key={ind}
                    href="e"
                    className={
                      ind % 2 === 0
                        ? "scan-button lss-btn"
                        : "reverted-scan-button lss-btn"
                    }
                  >
                    <div className="lss-sec">
                      <div className="avatar">
                        <span className="avatar-text">{val.rank}</span>
                      </div>
                      <h1 className="lss-btn-text">{val.name}</h1>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveStanding;
