import React, { useEffect, useState } from "react";
import socket from "../socket";
import { useNavigate } from "react-router-dom";
import TableHeader from "../common/components/TableHeader";
import SessionRecordRow from "../common/components/SessionRecordRow";
import MainHeading from "../common/components/MainHeading";

const SessionStats = () => {
  const tableHeaderData = [
    { name: "Rank", width: "5%", class: "" },
    { name: "Player", width: "17%", class: "" },
    { name: "team", width: "17%", class: "" },
    { name: "laps", width: "5%", class: "text-center" },
    { name: "overtake", width: "9%", class: "text-center" },
    { name: "c.w.c", width: "6%", class: "text-center" },
    { name: "c.w.e", width: "6%", class: "text-center" },
    { name: "Not Overtaken", width: "14%", class: "text-center" },
    { name: "Min Collision", width: "12%", class: "text-center" },
    { name: "Score", width: "7%", class: "text-center" },
  ];

  const navigate = useNavigate();

  const [sessionStats, setSessionStats] = useState([]);

  // get_session_stat_data
  useEffect(() => {
    socket.emit(
      "client_session_data",
      {
        message: "Give me the details of the session",
      },
      function (sessionData) {
        if (sessionData && Object.keys(sessionData).length > 0) {
          // Acknowledge Callback
          setSessionStats(sessionData);
        }
      }
    );
  }, []);

  // on new_connection - move to livestandingrank screen
  useEffect(() => {
    const handleNewUserJoin = () => {
      navigate("/livestandingrank");
    };

    socket.on("stat_to_live_screen", handleNewUserJoin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // move to championship stat page
  useEffect(() => {
    const handleChampionshipStat = (data) => {
      navigate("/championshipstats");
    };

    socket.on("move_championship_stat", handleChampionshipStat);

    return () => {
      socket.off("move_championship_stat", handleChampionshipStat);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="live-standing-rank"
      className="relative  !py-[2.875rem] w-full min-h-screen bg-no-repeat bg-cover bg-ranklivestanding-bgimage"
    >
      <div className="w-full flex flex-col items-center gap-4 ">
        <MainHeading title="Session standings!" />

        <div className="flex flex-col min-w-[90%]  ">
          <TableHeader elements={tableHeaderData} />
          <SessionRecordRow sessionData={sessionStats} />
        </div>
      </div>
    </div>
  );
};

export default SessionStats;
