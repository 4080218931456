import React, { useEffect } from "react";
import arrowIcon from "../common/assests/img/directions_alt.png";
import "../common/ui/css/ScanCode.css";
import socket from "../socket";
import { useNavigate } from "react-router-dom";
import image1 from "../common/assests/img/Rectangle 4.png";
import black from "../common/assests/img/Rectangle 5.png";
import effect from "../common/assests/img/effect.png";
import left from "../common/assests/img/left side.png";
import right from "../common/assests/img/right side.png";
import shape from "../common/assests/img/welcome.png";

import bgVideo from "../common/assests/video/heroVideo.mp4";
import MainHeading from "../common/components/MainHeading";

const WelcomeToRacePark = () => {
  const navigate = useNavigate();

  // new_session - move to live standing rank screen
  useEffect(() => {
    const handleNewSession = () => {
      navigate("/livestandingrank");
    };

    socket.on("new_session", handleNewSession);

    return () => {
      socket.off("new_session", handleNewSession);
    };
  }, [navigate]);

  // move to championship stat page
  useEffect(() => {
    const handleChampionshipStat = (data) => {
      navigate("/championshipstats");
    };

    socket.on("move_championship_stat", handleChampionshipStat);

    return () => {
      socket.off("move_championship_stat", handleChampionshipStat);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // move_before_session_screen - when admin set the status of the session to is_active
  // useEffect(() => {
  //   const handleCarCollision = (data) => {
  //     navigate("/beforesessionstart");
  //   };

  //   socket.on("move_before_session_screen", handleCarCollision);
  // }, []);

  return (
    <div className=" min-w-full min-h-screen   !overflow-hidden ">
      <div className="relative w-full h-screen overflow-hidden">
        <img
          className="left absolute z-[2] -left-14 bottom-0 !h-[40%] !w-[40%]  object-cover opacity-100"
          src={left}
          alt=" here"
        />

        <img
          className="effect s top-0 absolute z-[1] min-h-screen w-full object-cover opacity-50 "
          src={effect}
          alt=" here"
        />

        <img
          className="left absolute z-[0] -top-4 -right-14 !h-[40%] !w-[40%]  object-cover opacity-100"
          src={right}
          alt=" here"
        />

        <img
          className="black top-0 absolute z-[-1] min-h-screen w-full object-cover opacity-20"
          src={black}
          alt=" here"
        />

        <img
          className="gradient top-0 z-[-2] min-h-screen w-full object-cover opacity-40"
          src={image1}
          alt=" here"
        />

        <video
          autoPlay
          loop
          muted
          crossOrigin="anonymous"
          preload="auto"
          className="absolute z-[-2] top-0 w-[100%] min-h-screen object-cover opacity-100"
        >
          <source
            // src="https://video.wixstatic.com/video/222124_f14b1b743aea452eb5ea1cbf3fa00128/1080p/mp4/file.mp4"
            src={bgVideo}
            type="video/mp4"
          />
        </video>
      </div>

      <div className="z-10 center-data w-full  ">
        <div className=" flex flex-col items-center gap-4 ">
          <MainHeading
            title="WELCOME TO RACEPARK"
            class_name="scan-code-heading text-white kenyanCoffee text-[7.691rem]"
          />

          <div className="flex w-[70%]  max-h-[400px]  relative">
            <div className="child mt-8 !z-[40] w-full flex justify-center gap-4 items-center  ">
              <img
                src={arrowIcon}
                alt="Button Directions"
                className=" !max-w-[14%] object-cover"
              />

              <span className=" livvic italic text-white text-[3rem]  ">
                Are you Ready?
              </span>
            </div>

            <img
              src={shape}
              alt="shape here"
              className="child -z-10  h-auto  w-full object-cover mt-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeToRacePark;
