import React, { useEffect, useState } from "react";
import "../common/ui/css/ChampionshipStat.css";
import ChampionshipRecordRow from "../common/components/ChampionshipRecordRow";
import socket from "../socket";
import MainHeading from "../common/components/MainHeading";
import { useNavigate } from "react-router-dom";
import TableHeader from "../common/components/TableHeader";
import DataLoadingSkeleton from "../common/components/loader";

const ChampionshipStats = () => {
  const navigate = useNavigate();


  const tableHeaderData = [
    { name: "Rank", width: "15%", class: "" },
    { name: "Player", width: "30%", class: "" },
    { name: "Score", width: "15%", class: "text-center" },
    { name: "team", width: "30%", class: "" },
  ];

  const [championshipData, setChampionshipStats] = useState();

  // client_championship_data

  useEffect(() => {
    socket.emit(
      "client_championship_data",
      {
        message: "Give me the details of the championship",
      },
      function (data) {
        // Set the state if and only if data is not an empty object
        if (data && Object.keys(data).length > 0) {
          setChampionshipStats(data);
        }
      }
    );
  }, []);

  // new_session - move to welcome page on new_session

  useEffect(() => {
    const handleNewSession = () => {
      navigate("/");
    };

    socket.on("new_session", handleNewSession);

    return () => {
      socket.off("new_session", handleNewSession);
    };
  }, [navigate]);

  // new_connection - move to Live standing rank screen
  useEffect(() => {
    const handleNewUserJoin = () => {
      navigate("/livestandingrank");
    };

    socket.on("stat_to_live_screen", handleNewUserJoin);
  }, [navigate]);

  return (
    <div
      id="live-standing-rank"
      className=" !py-[2.875rem] main-container   bg-championshipstanding-bgimage "
    >
      <div className="flex justify-center ">
        <div className="flex flex-col w-[90%] ">
          <MainHeading
            title={championshipData && championshipData.championship_name}
          />
          <TableHeader elements={tableHeaderData} />

          {!championshipData ? (
            <DataLoadingSkeleton />
          ) : (
            championshipData && (
              <ChampionshipRecordRow champResults={championshipData} />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ChampionshipStats;
