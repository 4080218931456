import React, { useEffect } from "react";
// import { getPlayerNamebyIDAPI } from "../common/utils/service/player.service";
import "../common/ui/css/GoodLuck.css";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

// const dummyData = [{ name: "Player 1" }];
const GoodLuck = () => {
  // const [userName, setUserName] = useState();

  const params = useParams();
  // const playerID = params.playerID;

  const getData = async () => {
    try {
      // const response = await getPlayerNamebyIDAPI(playerID);
      // setUserName(response);
      // setUserName(dummyData);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    // getData();

    // Redirect after 5 seconds
    const timer = setTimeout(() => {
      navigate("/playerresult/:3");
    }, 5000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="main-container bg-goodluck-bgimage">
      <div className="body-wrap">
        <div className="body-data">
          <div className="body-wrap">
            <div>
              <h1 className="good-luck-heading kenyanCoffee">GOOD LUCK!</h1>
              <p className="good-luck-subheading livvic">
                Lorem ipsum dolor sit amet, consectetur adipi.
              </p>
            </div>

            <div className="gd-btn-wrap">
              <div className="good-luck-btn-tb">
                <hr className="good-luck-btn-bt-1" />
                <hr className="good-luck-btn-bt-2" />
              </div>

              <a href="w" className="good-luck-button livvic">
                <span className="good-luck-name">
                  {/* {userName && userName[0].name} */}
                  MICHEAL MILTON MICHEAL MILTON MICHEAL MILTON
                </span>
              </a>
              <div className="good-luck-btn-bb">
                <hr className="good-luck-btn-bb-1" />
                <hr className="good-luck-btn-bb-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodLuck;
