import React from "react";
import "../common/ui/css/CreateProfile.css";
import { useForm } from "react-hook-form";
import LabeledInput from "../common/ui/elements/input/LabeledInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateProfile = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      teamname: "",
    },
  });

  const handleOnClick = async (data) => {
    // Trim whitespace from form values
    const trimmedData = {
      name: data.name.trim(),
      teamname: data.teamname.trim(),
    };

    // Check if any field is empty after trimming
    if (!trimmedData.name || !trimmedData.teamname) {
      toast.error("Fields cannot be empty or just spaces.");
      return;
    }
    try {
      // await registerTeamAPI(data);
      toast.success(`${data.name} Thanks for registering ${data.teamname}`);
      reset({
        name: "",
        teamname: "",
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div
      style={{
        background: "linear-gradient(180deg, #056AE6 -9.61%, #C42121 106.76%)",
      }}
      className="mob-container"
    >
      <ToastContainer
        className=" text-gray-800 rounded-lg  absolute right-32 top-20 px-4"
        autoClose={4000}
      />
      <h2 className="kenyanCoffee profile-h">Create Profile</h2>

      <p className="livvic profile-p">
        Lorem ipsum dolor sit amet, consectetur adipi.
      </p>

      <div className="create-profile-card ">
        <div className=" card-top-shape" />

        <div className="">
          <form action="#" className="create-profile-form">
            <LabeledInput
              name="name"
              control={control}
              errors={errors}
              label="Name"
              rules={{ required: "Name is required" }}
            />

            <LabeledInput
              name="teamname"
              control={control}
              errors={errors}
              label="Team Name"
              rules={{ required: "Team name is required" }}
            />

            <div
              className="create-profile-btn-wrap"
              onClick={handleSubmit(handleOnClick)}
            >
              <a href="w" className="create-profile-btn">
                <span className="btn-text">Submit</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
