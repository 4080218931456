import React from "react";
import "../common/ui/css/WelcomePlayer.css";
import playerPic from "../common/assests/img/player-img.png";

const dummyData = [{ name: "Player 1" }];

const WelcomePlayer = () => {
  return (
    <div className="welcome-player-container parent">
      <div className="one"></div>

      <div className="two">
        <video
          id="comp-lrutpsdg_video"
          className="K8MSra"
          crossOrigin="anonymous"
          autoPlay={true}
          loop={true}
          preload="auto"
          tabIndex="-1"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center top",
            opacity: "1",
          }}
        >
          <source
            src="https://video.wixstatic.com/video/222124_f14b1b743aea452eb5ea1cbf3fa00128/1080p/mp4/file.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <a id="play-video" className="video-play-button" href="www.">
          <span></span>
        </a>
      </div>

      <div className="welcome-player-body-part">
        <div className="welcome-player-left-part">
          <div>
            <h1 className="player-welcome-h kenyanCoffee">
              WELCOME - PLAYER 1
            </h1>
          </div>
          <div>
            <p className="player-welcome-p livvic">
              Lorem ipsum dolor sit amet, consectetur adipi.
            </p>
          </div>
        </div>

        <div className="welcome-player-card">
          <div className="welcome-player-card-data">
            <div>
              <img src={playerPic} alt="" className="player-img" />
            </div>
            <div className="welcome-player-details">
              <div className="welcome-player-name-wrap">
                <h2 className="welcome-player-name-h livvic">
                  {dummyData[0].name}
                </h2>
              </div>
              <div className="welcome-player-detail-wrap">
                <p className="welcome-player-name-p livvic">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePlayer;
