export const GetOrdinalSuffix = (position) => {
  if (position < 1) {
    throw new Error("Position starts from 1");
  }

  switch (position) {
    case 1:
      return `${position}st`;
    case 2:
      return `${position}nd`;
    case 3:
      return `${position}rd`;
    default:
      return `${position}th`;
  }
};

export const ExtractFirstLetter = (name) => {
  return name && name.slice(0, 1);
};
