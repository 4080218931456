import React from "react";

import "../common/ui/css/SessionComplete.css";
const SessionComplete = () => {
  return (
    <div className="_mob-container bg-sessioncomplate-bgimage">
      <div className="session-card">
        <h1 className="session-h kenyanCoffee">Complete Session!</h1>
        <p className="session-p livvic">
          You completed your race, do you want play again?
        </p>
        <div className="session-btn-wrap">
          <a href="a" className="session-btn livvic">
            <span className="btn-text">Play Again?</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SessionComplete;
