import React, { useEffect, useState } from "react";
import "../common/ui/css/BeforeSessionStart..css";
import socket from "../socket";
import { useNavigate } from "react-router-dom";

const BeforeSessionStart = () => {
  const navigate = useNavigate();

  // let dummyData = {
  //   total_teams: 3,
  //   teamPlayerCounts: [
  //     {
  //       team_name: "Ferrari",
  //       playerCount: 0,
  //     },
  //     {
  //       team_name: "Red Bull",
  //       playerCount: 0,
  //     },
  //   ],
  //   updated_Data: {
  //     _id: "66911afad298cd9cfe341eba",
  //     session_name: "Session name here",
  //     version: 4,
  //     session_index: 1,
  //     current_session_index: 1,
  //     session_count: 3,
  //     server_name: "Assetto Corsa Server - Losail Circuit, Qatar",
  //     track: "actk_losail_circuit",
  //     track_config: "",
  //     name: "Qualify",
  //     type: 2,
  //     time: 0,
  //     laps: 0,
  //     wait_time: 0,
  //     ambient_temp: 26,
  //     road_temp: 36,
  //     weather_graphics: "3_clear",
  //     elapsed_ms: 0,
  //     is_active: false,
  //     session_teams: [
  //       {
  //         _id: "6686a5ac56c02ebf438c454a",
  //         team_name: "Red Bull",
  //         __v: 0,
  //         createdAt: "2024-07-04T13:37:48.397Z",
  //         updatedAt: "2024-07-04T13:37:48.397Z",
  //       },
  //       {
  //         _id: "6686a5ac56c02ebf438c454f",
  //         team_name: "Fast Crew",
  //         __v: 0,
  //         createdAt: "2024-07-04T13:37:48.397Z",
  //         updatedAt: "2024-07-04T13:37:48.397Z",
  //       },
  //     ],
  //     is_start: true,
  //     createdAt: "2024-07-12T12:00:58.745Z",
  //     updatedAt: "2024-07-18T06:39:12.974Z",
  //     __v: 0,
  //   },
  // };

  const [sessionData, setSessionData] = useState();

  // recieve_session_data
  useEffect(() => {
    const handleSessionData = (data) => {
      setSessionData(data);
    };

    socket.on("recieve_session_data", handleSessionData);
  }, []);

  // new_connection - move to live standing rank
  useEffect(() => {
    const handleConnectionData = (data) => {
      navigate("/livestandingrank");
    };

    socket.on("move_to_live_result", handleConnectionData);
  }, []);

  return (
    <div
      id="player-result"
      className="main-container top-container bg-playerresult-bgimage"
    >
      <div className="results-data">
        <div className="result-row">
          <div className=" flex items-center justify-between top-sec">
            <div className="pr-body-wrap">
              <div className="pr-num-wrapper livvic">
                <h1 className="player-num-h">
                  <sup className="player-num-h-sup">02:30 PM</sup>
                </h1>
              </div>
              <div className="pr-h-wrapper">
                <h2 className="pr-text-h">
                  <span>Racepark Breda</span>
                </h2>
                <div>
                  <h2 className="pr-user-name livvic">session name</h2>
                </div>
              </div>
            </div>

            <div className="total-scores-wrap livvic">
              <h2 className="total-score-h ">
                Session:{" "}
                <span className="total-score-num">
                  {`${
                    sessionData && sessionData.updated_Data.time
                      ? sessionData.updated_Data.time
                      : "00"
                  } mins`}
                </span>
              </h2>
            </div>
          </div>

          {sessionData &&
            sessionData.teamPlayerCounts.map((val, ind) => {
              return (
                <div key={ind} className="result-data-row livvic text-white">
                  <div className="flex-col items-center justify-between w-full">
                    <div className="flex items-center justify-between w-full">
                      <h1 className="result-data-text ">Team Name</h1>
                      <h1 className="result-data-num">{val.team_name} </h1>
                    </div>

                    <div className="flex items-center justify-between w-full">
                      <h1 className="result-data-text ">Total Players</h1>
                      <h1 className="result-data-num">{val.playerCount}</h1>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BeforeSessionStart;
