import React from "react";
import { Controller } from "react-hook-form";
import "../../css/CreateProfile.css";
const LabeledInput = ({
  name,
  control,
  rules,
  errors,
  label,
  type = "text",
}) => {
  return (
    <div className="form-group">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <input
            {...field}
            type={type}
            className="form-control bg-transparent"
            placeholder=" "
            id={name}
          />
        )}
      />
      {errors[name] && (
        <p className="text-red-900 mt-[10px] livvic">{errors[name].message}</p>
      )}
      <label htmlFor={name} className="animated-label">
        {label}
      </label>
    </div>
  );
};

export default LabeledInput;
