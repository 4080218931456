import React, { useEffect, useState } from "react";
import "../common/ui/css/PlayerResult.css";
import { GetOrdinalSuffix } from "../common/utils/functions";
// import { useNavigate } from "react-router-dom";
// import socket from "../socket";

const PlayerResult = () => {
  // const navigate = useNavigate();
  const [playerResult, setPlayerResult] = useState([]);

  // useEffect(() => {
  //   socket.on("lap_completed", (data) => {
  //     setPlayerResult([...playerResult, data]);
  //   });

  //   socket.on("collision_with_environment", (data) => {
  //     setPlayerResult([...playerResult, data]);
  //   });

  //   socket.on("connection_closed", (data) => {
  //     navigate("/livestandingrank");
  //   });
  // });

  // useEffect(() => {
  //   localStorage.setItem("Event", JSON.stringify(playerResult));
  // }, [playerResult]);

  useEffect(() => setPlayerResult([]), []);

  const totalScore =
    playerResult && playerResult.reduce((acc, val) => acc + val.value, 0);

  return (
    <div
      id="player-result"
      className="main-container top-container bg-playerresult-bgimage"
    >
      <div className="results-data">
        <div className="result-row">
          <div className=" flex items-center justify-between top-sec">
            <div className="pr-body-wrap">
              <div className="pr-num-wrapper livvic">
                <h1 className="player-num-h">
                  <sup className="player-num-h-sup">{GetOrdinalSuffix(2)}</sup>
                </h1>
              </div>
              <div className="pr-h-wrapper">
                <h2 className="pr-text-h">
                  <span>Race Results</span>
                </h2>
                <div>
                  <h2 className="pr-user-name livvic">Name</h2>
                </div>
              </div>
            </div>

            <div className="total-scores-wrap livvic">
              <h2 className="total-score-h ">
                Total Score:{" "}
                <span className="total-score-num">{totalScore}</span>
              </h2>
            </div>
          </div>
          {playerResult &&
            playerResult.map((val, ind) => (
              <div key={ind} className="result-data-row livvic">
                <h1 className="result-data-text ">{val.event}</h1>
                <h1 className="result-data-num">
                  {val.value > 0 ? `+${val.value}` : val.value}
                </h1>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PlayerResult;
