import React from "react";

function TableHeader({ elements }) {
  if (!elements || elements.length === 0) {
    return null;
  }
  return (
    <div className="border-[0.125rem] rounded-[0.75rem] w-full uppercase bg-[#3c91f9] shadow-custom-inset border-solid border-[#ffffff] Livvic flex items-center justify-between pt-[20px] pr-[20px] pb-[20px] pl-[20px] md:pt-[20px] md:pr-[20px] md:pb-[20px] md:pl-[20px]">
      {elements &&
        elements?.map((val, ind) => (
          <div
            key={ind}
            className={` w-[${val.width}]  font-semibold text-white ${val.class}`}
          >
            <h1 className="result-data-text  livvic">{val.name}</h1>
          </div>
        ))}
    </div>
  );
}

export default TableHeader;
