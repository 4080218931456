import React, { useEffect, useState } from "react";
import arrowIcon from "../common/assests/img/directions_alt.png";
import QRcode from "../common/assests/img/bar-code.png";
// import { getSessionbyIDAPI } from "../common/utils/service/session.service";
import "../common/ui/css/ScanCode.css";
import socket from "../socket";

const ScanCode = () => {
  const [qrCodeData, setQrCodeData] = useState("");

  useEffect(() => {
    socket.on("client_event", (data) => {});
    socket.on("new_connection", (data) => {
      // Redirect to Welcome Player
      // window.location.href = "http://localhost:3000/welcomeplayer/2";
      window.location.href = `${process.env.REACT_BASE_URL}welcomeplayer/2`;
    });

    socket.on("session_info", (data) => {});

    socket.on("car_info", (data) => {});
  });
  // const getQRCode = async () => {
  //   const sessionID = "6666d9fa23bc019b22d6141a";
  //   try {
  //     const response = await getSessionbyIDAPI(sessionID);
  //     setQrCodeData(response.session.qrCodeData);
  //     const enddate = new Date(response.session.enddate);

  //     const currentDate = new Date();
  //     const differenceInMilliseconds = enddate - currentDate;
  //     const differenceInSeconds = differenceInMilliseconds / 1000;

  //     const differenceInMinutes = differenceInSeconds / 60;
  //     console.log("Difference in minutes ===>", differenceInMinutes);
  //     if (differenceInMilliseconds > 0) {
  //       // Set a timeout to alert when the session expires
  //       setTimeout(() => {
  //         console.log("==>Session Expired");
  //       }, differenceInMilliseconds);
  //     } else {
  //       // If the session is already expired
  //       console.log("$$$====> Session Expired");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching session data:", error);
  //   }
  // };
  // useEffect(() => getQRCode, []);

  return (
    <div className="main-container  bg-scancode-bgimage">
      <div className=" center-data w-full">
        <div className="body-wrap">
          <h1 className="scan-code-heading kenyanCoffee">
            WELCOME TO RACEPARK
          </h1>
          <div className="btn-wrap flex flex-col">
            <a href="w" className="scan-button livvic ml-0">
              <img
                src={arrowIcon}
                alt="Button Directions"
                className="scan-btn-img"
              />
              <span className="btn-text pl-6">Scan this code to start!</span>
            </a>
          </div>
        </div>

        <div className="qr-barcode-wrap">
          <img
            src={qrCodeData ? qrCodeData : QRcode}
            alt="QR Code"
            className="qr-barcode"
          />
          <span className="border_btm"></span>
        </div>
      </div>
    </div>
  );
};

export default ScanCode;
