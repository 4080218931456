import React, { useEffect } from "react";
import FlagPic from "../common/assests/img/get-ready-flag.png";
import "../common/ui/css/GetReady.css";
// import { getPlayerNamebyIDAPI } from "../common/utils/service/player.service";
// import { useParams } from "react-router-dom";

// const dummyData = [{ name: "Player 1" }];

const GetReady = () => {
  // const [userName, setUserName] = useState();

  // const params = useParams();
  // const playerID = params.playerID;

  const getData = async () => {
    try {
      // const response = await getPlayerNamebyIDAPI(playerID);
      // setUserName(response);
      // setUserName(dummyData);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="main-container bg-getReady-bgimage">
      <div className="body-data">
        <div className="get-ready-wrap">
          <img className="get-ready-flag" alt="Get Ready Flag" src={FlagPic} />
        </div>
        <p className="good-luck-subheading livvic">Next RACER!</p>
        <div className="gd-btn-wrap">
          <div className="good-luck-btn-tb">
            <hr className="good-luck-btn-bt-1" />
            <hr className="good-luck-btn-bt-2" />
          </div>
          <a href="w" className="good-luck-button livvic">
            <span className="good-luck-name">
              {/* {userName && userName[0].name} */}
              MICHEAL MILTON MICHEAL MILTON MICHEAL MILTON
            </span>
          </a>
          <div className="good-luck-btn-bb">
            <hr className="good-luck-btn-bb-1" />
            <hr className="good-luck-btn-bb-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetReady;
